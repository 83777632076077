@font-face {
    font-family: "RetroGaming";
    src: url("./RetroGaming.ttf");
}

body {
    font-family: 'RetroGaming', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #222;
    height: 100%;
    color: rgb(255, 255, 255);
}

.bg{
    /* The image used */
    background-image: url("./background.jpg");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


@media screen { 
  
    /* start of screen rules. */ 
    
    /* Generic pane rules */
    body { margin: 0 }
    .row, .col { overflow: hidden; position: absolute; }
    .row { left: 0; right: 0; }
    .col { top: 0; bottom: 0; }
    .scroll-x { overflow-x: auto; }
    .scroll-y { overflow-y: auto; }
  
    .header.row { height: 75px; top: 0; }
    .body.row { top: 75px; bottom: 50px; }
    .footer.row { height: 50px; bottom: 0; }
    
    /* end of screen rules. */ 
  }

.header {
    background-color: black;
}

.window {
    width: 50%;
    margin: 50px auto;
    border: 1px solid black;
    background-color: rgba(15, 15, 15, 0.418);
}

.text-input-window {
    width: 50%;
    margin: -40px auto;
}

.text-input {
    width: 100%;
    border: 1px solid black;
    background-color: rgba(15, 15, 15, 0.418);
    color: white;
    white-space: pre;
}

.submit-button {
    width: 70px;
    float: right;
    white-space: pre;
    border: 1px solid black;
    background-color: rgba(15, 15, 15, 0.418);
}

.maintext {
    height: 70%;
    overflow-y: scroll;
}

.text-assistant {
    color: rgb(140, 233, 140);
}

.text-user {
    color: rgb(255, 255, 255);
}

h1 {
    color: #f7f7f7;
    text-align: center;
    font-weight: 800em;
    font-size: x-large;
    margin-bottom: 1em;
}

h2 {
    color: #f7f7f7;
    text-align: center;
    font-weight: 800em;
    font-size: large;
    margin-bottom: 1em;
}

a {
    text-decoration-line: underline;
}
